import expincLogo from './images/expinc-logo.svg'
import bloomLogoCircle from './images/bloom-logo.png'
import bloomLogo from './images/bloom.png'
import instructie from './images/instructie.png'
import instelling from './images/instelling.png'

import periodHands from './images/period_hands-helping.svg'
import periodJournal from './images/period_journal.svg'
import periodMirror from './images/period_mirror.svg'
import periodSolarBath from './images/period_solar_bath.svg'

// Emotional
import angstig from './images/symptoms/emotionals/angstig_symptom.png'
import blij from './images/symptoms/emotionals/blij_symptom.png'
import boos from './images/symptoms/emotionals/boos_symptom.png'
import gefrustreerd from './images/symptoms/emotionals/gefrustreerd_symptom.png'
import gejaagd from './images/symptoms/emotionals/gejaagd_symptom.png'
import gelukkig from './images/symptoms/emotionals/gelukkig_symptom.png'
import gemotiveerd from './images/symptoms/emotionals/gemotiveerd_symptom.png'
import gespannen from './images/symptoms/emotionals/gespannen_symptom.png'
import gestrest from './images/symptoms/emotionals/gestrest_symptom.png'
import hoopvol from './images/symptoms/emotionals/hoopvol_symptom.png'
import inbalans from './images/symptoms/emotionals/inbalans_symptom.png'
import ontspannen from './images/symptoms/emotionals/ontspannen_symptom.png'
import onzeker from './images/symptoms/emotionals/onzeker_symptom.png'
import prikkelbaar from './images/symptoms/emotionals/prikkelbaar_symptom.png'
import somber from './images/symptoms/emotionals/somber_symptom.png'
import stemmingSwisselingen from './images/symptoms/emotionals/stemming-swisselingen_symptom.png'
import verdrietig from './images/symptoms/emotionals/verdrietig_symptom.png'
import zelfverzekerd from './images/symptoms/emotionals/zelfverzekerd_symptom.png'

// Mentals
import afgeleid from './images/symptoms/mentals/afgeleid_symptom.png'
import compassievol from './images/symptoms/mentals/compassievol_symptom.png'
import creatief from './images/symptoms/mentals/creatief_symptom.png'
import gefocust from './images/symptoms/mentals/gefocust_symptom.png'
import kritischeGedachten from './images/symptoms/mentals/kritische-gedachten_symptom.png'
import negatieveGedachten from './images/symptoms/mentals/negatieve-gedachten_symptom.png'
import oplossingsgericht from './images/symptoms/mentals/oplossingsgericht_symptom.png'
import optimistisch from './images/symptoms/mentals/optimistisch_symptom.png'
import overweldigd from './images/symptoms/mentals/overweldigd_symptom.png'
import piekeren from './images/symptoms/mentals/piekeren_symptom.png'
import probleemgericht from './images/symptoms/mentals/probleemgericht_symptom.png'

// Physicals
import acne from './images/symptoms/physicals/acne_symptom.png'
import bewust from './images/symptoms/physicals/bewust_symptom.png'
import diarree from './images/symptoms/physicals/diarree_symptom.png'
import energiek from './images/symptoms/physicals/energiek_symptom.png'
import fit from './images/symptoms/physicals/fit_symptom.png'
import gespannenHouding from './images/symptoms/physicals/gespannen-houding_symptom.png'
import gevoeligeBorsten from './images/symptoms/physicals/gevoelige-borsten_symptom.png'
import gezond from './images/symptoms/physicals/gezond_symptom.png'
import hoofdpijn from './images/symptoms/physicals/hoofdpijn_symptom.png'
import krampen from './images/symptoms/physicals/krampen_symptom.png'
import meerTrek from './images/symptoms/physicals/meer-trek_symptom.png'
import misselijk from './images/symptoms/physicals/misselijk_symptom.png'
import ontspannenAdemhaling from './images/symptoms/physicals/ontspannen-ademhaling_symptom.png'
import ontspannenHouding from './images/symptoms/physicals/ontspannen-houding_symptom.png'
import opgeblazenGevoel from './images/symptoms/physicals/opgeblazen-gevoel_symptom.png'
import rugpijn from './images/symptoms/physicals/rugpijn_symptom.png'
import slaapproblemen from './images/symptoms/physicals/slaapproblemen_symptom.png'
import sterk from './images/symptoms/physicals/sterk_symptom.png'
import vermoeid from './images/symptoms/physicals/vermoeid_symptom.png'
import vochtVasthouden from './images/symptoms/physicals/vocht-vasthouden_symptom.png'

// Socials
import afgezonderd from './images/symptoms/socials/afgezonderd_symptom.png'
import eenzaam from './images/symptoms/socials/eenzaam_symptom.png'
import geduldig from './images/symptoms/socials/geduldig_symptom.png'
import gesteund from './images/symptoms/socials/gesteund_symptom.png'
import gevoeligVoorAfwijzing from './images/symptoms/socials/gevoelig-voor-afwijzing_symptom.png'
import meTerugtrekken from './images/symptoms/socials/me-terugtrekken_symptom.png'
import onenigheidMetAnderen from './images/symptoms/socials/onenigheid-met-anderen_symptom.png'
import samen from './images/symptoms/socials/samen_symptom.png'
import verbonden from './images/symptoms/socials/verbonden_symptom.png'
import zinInGezelschap from './images/symptoms/socials/zin-in-gezelschap_symptom.png'
import zorgzaam from './images/symptoms/socials/zorgzaam_symptom.png'

import geen from './images/geen.png'

import confetti from './images/Confetti.gif'

import tool1 from './images/tool1.png'
import tool2 from './images/tool2.png'
import tool3 from './images/tool3.png'
import tool4 from './images/tool4.png'
import tool5 from './images/tool5.png'
import bg1 from './images/bg1.png'

import quotesBg from './images/misc/quoteBg.png'
import quotePict from './images/misc/quotePict.png'
import startQuote from './images/misc/startQuote.png'
import endQuote from './images/misc/endQuote.png'
import terms from './images/terms.svg'

import underDevelopment from './images/misc/underDevelopment.svg'

import step1 from './images/steps/step1.png'
import step2 from './images/steps/step2.png'
import step3 from './images/steps/step3.png'
import step4 from './images/steps/step4.png'
import step51 from './images/steps/step5-1.png'
import step52 from './images/steps/step5-2.png'
import step6 from './images/steps/step6.png'

import notif1 from './images/notif1.png'
import notif2 from './images/notif2.png'

const assets = {
  gif: {
    confetti,
  },
  images: {
    steps: {
      step1,
      step2,
      step3,
      step4,
      step51,
      step52,
      step6,
    },
    symptoms: {
      geen,
      emotional: {
        angstig,
        blij,
        boos,
        gefrustreerd,
        gejaagd,
        gelukkig,
        gemotiveerd,
        gespannen,
        gestrest,
        hoopvol,
        inbalans,
        ontspannen,
        onzeker,
        prikkelbaar,
        somber,
        stemmingSwisselingen,
        verdrietig,
        zelfverzekerd,
      },
      mental: {
        afgeleid,
        compassievol,
        creatief,
        gefocust,
        kritischeGedachten,
        negatieveGedachten,
        oplossingsgericht,
        optimistisch,
        overweldigd,
        piekeren,
        probleemgericht,
      },
      physical: {
        acne,
        bewust,
        diarree,
        energiek,
        fit,
        gespannenHouding,
        gevoeligeBorsten,
        gezond,
        hoofdpijn,
        krampen,
        meerTrek,
        misselijk,
        ontspannenAdemhaling,
        ontspannenHouding,
        opgeblazenGevoel,
        rugpijn,
        slaapproblemen,
        sterk,
        vermoeid,
        vochtVasthouden,
      },
      social: {
        afgezonderd,
        eenzaam,
        geduldig,
        gesteund,
        gevoeligVoorAfwijzing,
        meTerugtrekken,
        onenigheidMetAnderen,
        samen,
        verbonden,
        zinInGezelschap,
        zorgzaam,
      },
    },
    bloomLogoCircle,
    bloomLogo,
    expincLogo,
    instructie,
    instelling,
    tool1,
    tool2,
    tool3,
    tool4,
    tool5,
    bg1,
    quotesBg,
    quotePict,
    startQuote,
    endQuote,
    terms,
    underDevelopment,
    notif1,
    notif2,
  },
  icons: {
    periodHands,
    periodJournal,
    periodMirror,
    periodSolarBath,
  },
};

export default assets
