/**
 * @file global.tsx
 * This file contains global configurations used throughout the project.
 */

/**
 * `global` - an object to store the environment configuration.
 * @type {Object}
 * @property {string} environment - Specifies the working environment.
 *                                  'prod' for production,
 *                                  'dev' for development,
 *                                  'local' for local development.
 */
export const global = {
  environment: 'prod',
  // Storage name in azure storage.
  storage_name: 'bloomaistorage',

  // Container name for specific data within the storage system.
  container_name: 'b4eb7b71-f032-474e-9f37-47f50361511c-azureml-blobstore',

  // SAS (Shared Access Signature) token for Azure Storage access control.
  // Provides permissions to read, write, and delete within the specified resources.
  // Includes parameters for storage version, service type, resource types,
  sas_token:
    '?sp=racwdli&st=2024-03-18T14:57:28Z&se=2025-03-01T00:11:54Z&sv=2022-11-02&sr=c&sig=uxRqD7KIS7qJliqtjNU51%2F5ulLZD5Ddpxoxe05aFhM8%3D',
}

/**
 * `base_url_be` - Base URL for backend services.
 * Determines the URL based on the selected environment setting.
 * @type {string}
 */
export const base_url_be =
  global.environment == 'prod'
    ? 'https://bloom-be-prod.azurewebsites.net/'
    : global.environment == 'dev'
    ? 'https://bloom-be-dev.azurewebsites.net/'
    : 'http://127.0.0.1:3333/'

/**
 * `base_url` - Base URL for the frontend services.
 * Sets the URL according to the environment setting.
 * @type {string}
 */
export const base_url =
  global.environment == 'prod'
    ? 'https://bloomapp.nl/'
    : global.environment == 'dev'
    ? 'https://bloom-dev.azurewebsites.net/'
    : 'http://localhost:5173/'
