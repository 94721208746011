import { ComponentType, ReactNode, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { RouteObject } from 'react-router'

import SidebarLayout from '../pages/admins/layouts/SidebarLayout'
import BaseLayout from '../pages/admins/layouts/BaseLayout'

import SuspenseLoader from '../components/SuspenseLoader'

const Loader =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P): ReactNode =>
    (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    )

// Pages
const Overview = Loader(lazy(() => import('../pages/admins/content/overview')))

// On Boarding page
const OnBoardingStep1 = Loader(
  lazy(() => import('../pages/admins/content/onboardings/step1'))
)
const OnBoardingStep2 = Loader(
  lazy(() => import('../pages/admins/content/onboardings/step2'))
)
const OnBoardingStep3 = Loader(
  lazy(() => import('../pages/admins/content/onboardings/step3'))
)
const OnBoardingStep4 = Loader(
  lazy(() => import('../pages/admins/content/onboardings/step4'))
)
const OnBoardingStep4Validate = Loader(
  lazy(() => import('../pages/admins/content/onboardings/step4Validate'))
)
const OnBoardingStep5_1 = Loader(
  lazy(() => import('../pages/admins/content/onboardings/step5-1'))
)
const OnBoardingStep5_1_2 = Loader(
  lazy(() => import('../pages/admins/content/onboardings/step5-1-2'))
)
const OnBoardingStep5_1_3 = Loader(
  lazy(() => import('../pages/admins/content/onboardings/step5-1-3'))
)
const OnBoardingStep5_2 = Loader(
  lazy(() => import('../pages/admins/content/onboardings/step5-2'))
)
const OnBoardingStep6 = Loader(
  lazy(() => import('../pages/admins/content/onboardings/step6'))
)

// Applications
const ChatAI = Loader(
  lazy(() => import('../pages/admins/content/applications/ChatAi'))
)
const PeriodHistory = Loader(
  lazy(() => import('../pages/admins/content/applications/Calendar'))
)
const FeelingDiary = Loader(
  lazy(() => import('../pages/admins/content/applications/Psychologie/index'))
)
const FeelingChart = Loader(
  lazy(() => import('../pages/admins/content/applications/FeelingChart/index'))
)

const UserProfile = Loader(
  lazy(() => import('../pages/admins/content/applications/Users/profile'))
)
const UserSettings = Loader(
  lazy(() => import('../pages/admins/content/applications/Users/settings'))
)

// management
const ManagementUploadData = Loader(
  lazy(() => import('../pages/admins/content/management/UploadData'))
)
const ManagementInstruction = Loader(
  lazy(() => import('../pages/admins/content/management/Instruction'))
)
const ManagementBotTrainer = Loader(
  lazy(() => import('../pages/admins/content/management/BotTrainer'))
)
const ManagementSetting = Loader(
  lazy(() => import('../pages/admins/content/management/Setting'))
)
const ManagementInvite = Loader(
  lazy(() => import('../pages/admins/content/management/Invite'))
)

// Status
// const Status404 = Loader(
//   lazy(() => import('../pages/admins/content/pages/Status/Status404'))
// )

// Auth
const AuthVerifyAccount = Loader(
  lazy(() => import('../pages/auth/verifyAccount'))
)
const AuthOtpCode = Loader(lazy(() => import('../pages/auth/otpCode')))
const AuthForgotPassword = Loader(
  lazy(() => import('../pages/auth/forgotPassword'))
)
const ResetPassword = Loader(lazy(() => import('../pages/auth/resetPassword')))
const ChangePassword = Loader(
  lazy(() => import('../pages/auth/changePassword'))
)
const Invitation = Loader(lazy(() => import('../pages/auth/invitation')))

// Whatsapp Setting
const ChangeWhatsappNumber = Loader(
  lazy(
    () =>
      import(
        '../pages/admins/content/applications/Users/settings/Whatsapps/ChangeWhatsappNumber'
      )
  )
)
const WhatsappVerifyOTP = Loader(
  lazy(
    () =>
      import(
        '../pages/admins/content/applications/Users/settings/Whatsapps/VerifyOTP'
      )
  )
)
const DoneChangeWhatsapp = Loader(
  lazy(
    () =>
      import(
        '../pages/admins/content/applications/Users/settings/Whatsapps/DoneChangeWhatsapp'
      )
  )
)

const routesDev: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/dashboards" replace />,
      },
      {
        path: '/auth',
        children: [
          {
            path: '',
            element: <Navigate to="login" replace />,
          },
          {
            path: 'forgot-password',
            element: <AuthForgotPassword />,
          },
          {
            path: 'verify-email/:token',
            element: <AuthVerifyAccount />,
          },
          {
            path: 'otp-code',
            element: <AuthOtpCode />,
          },
          {
            path: 'reset-password',
            element: <ResetPassword />,
          },
          {
            path: 'change-password',
            element: <ChangePassword />,
          },
          {
            path: 'invitation/:token',
            element: <Invitation />,
          },
        ],
      },
      {
        path: '/onboarding',
        children: [
          {
            path: '',
            element: <Navigate to="step1" replace />,
          },
          {
            path: 'step1',
            element: <OnBoardingStep1 />,
          },
          {
            path: 'step2',
            element: <OnBoardingStep2 />,
          },
          {
            path: 'step3',
            element: <OnBoardingStep3 />,
          },
          {
            path: 'step4',
            element: <OnBoardingStep4 />,
          },
          {
            path: 'step4/validate',
            element: <OnBoardingStep4Validate />,
          },
          {
            path: 'step5',
            element: <OnBoardingStep5_1 />,
          },
          {
            path: 'step5/contraception',
            element: <OnBoardingStep5_1_2 />,
          },
          {
            path: 'step5/contraception2',
            element: <OnBoardingStep5_1_3 />,
          },
          {
            path: 'step5/menstruatiecyclus',
            element: <OnBoardingStep5_2 />,
          },
          {
            path: 'step6',
            element: <OnBoardingStep6 />,
          },
        ],
      },
      {
        path: '/dashboards',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="psychologie" replace />,
          },
          {
            path: 'chat-ai',
            element: <ChatAI />,
          },
          {
            path: 'kalender',
            element: <PeriodHistory />,
          },
          {
            path: 'psychologie',
            element: <FeelingDiary />,
          },
          {
            path: 'feeling-chart',
            element: <FeelingChart />,
          },
          {
            path: 'profile',
            children: [
              {
                path: '',
                element: <Navigate to="details" replace />,
              },
              {
                path: 'details',
                element: <UserProfile />,
              },
              {
                path: 'settings',
                children: [
                  {
                    path: '',
                    element: <UserSettings />,
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: '/user',
        children: [
          {
            path: 'change-whatsapp',
            element: <ChangeWhatsappNumber />,
          },
          {
            path: 'change-whatsapp/otp-code',
            element: <WhatsappVerifyOTP />,
          },
          {
            path: 'change-whatsapp/complete',
            element: <DoneChangeWhatsapp />,
          },
        ],
      },

      {
        path: 'management',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="instructions" replace />,
          },
          {
            path: 'instructions',
            element: <ManagementInstruction />,
          },
          {
            path: 'bot-trainer',
            element: <ManagementBotTrainer />,
          },
          {
            path: 'upload-data',
            element: <ManagementUploadData />,
          },
          {
            path: 'setting',
            element: <ManagementSetting />,
          },

          {
            path: 'invite',
            element: <ManagementInvite />,
          },
        ],
      },
      {
        path: 'overview',
        element: <Overview />,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
]

export default routesDev
