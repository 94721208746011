import { IconButton, Tooltip } from '@mui/material'
import { useRef } from 'react'
import { TbTooltip } from 'react-icons/tb'
import { generalTooltips } from '../../../../../../../constants/tooltips/GeneralTooltips'
import { useNavigate } from 'react-router'

function HeaderTooltips() {
  const ref = useRef<any>(null)
  const navigate = useNavigate()
  const handleOpen = (): void => {
    navigate('/dashboards/psychologie')
    setTimeout(() => {
      generalTooltips.drive()
    }, 100)
  }

  return (
    <>
      <Tooltip arrow title="Tooltips">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <TbTooltip />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default HeaderTooltips
