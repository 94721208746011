import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
import './style.css'

export const generalTooltips = driver({
  showProgress: true,
  steps: [
    {
      element: '#psychologie-menu-register',
      popover: {
        title: 'Welkom bij de Bloom-app!',
        description:
          'Laten we samen een korte tour maken door de app en de belangrijkste functies bespreken.',
        side: 'bottom',
        align: 'start',
      },
    },
    {
      element: '#psychologie-menu-register',
      popover: {
        description:
          'In dit blok hou je dagelijks je emotionele, mentale, fysieke en sociale welzijn bij. We vragen je om elke ochtend een kort moment te nemen om te noteren hoe het met je gaat. De lijnen kun je interpreteren als een schaal van 0-10, waarbij je het punt kunt aanklikken dat past bij hoe je je voelt. Je kunt daaronder specifieker aangeven hoe het met je gaat door een toelichting te typen en de buttons aan te klikken die dag bij je passen. Door dit dagelijks te registreren, creëer je een waardevolle bron van informatie in het menu Statistieken.',
        side: 'bottom',
        align: 'start',
      },
    },
    {
      element: '#psychologie-menu-reflexi',
      popover: {
        description:
          'Dit is jouw persoonlijke dagboek waarin je kunt reflecteren op je dag en van je af kunt schrijven wanneer je dat wilt.',
        side: 'left',
        align: 'start',
      },
    },
    {
      element: '#psychologie-advice',
      popover: {
        description:
          'Dit blok biedt meer informatie over de fase van de menstruatiecyclus waarin jij je bevindt. Het is volkomen normaal om je soms wat minder energiek of emotioneel te voelen tijdens bepaalde fases van je menstruatiecyclus. Er zijn ook fases waarin je je juist weer beter zal voelen. Het is goed om verwachtingen aan te passen en begripvol te zijn voor jezelf ten opzichten van jouw fases.',
        side: 'top',
        align: 'start',
      },
    },
    {
      element: '#menu-kalender',
      popover: {
        description:
          'Hier vind je jouw kalender met een voorspelling van je menstruatiecyclus. Gebruik deze kalender om je cyclus bij te houden en te anticiperen op wat er komen gaat.',
        side: 'right',
        align: 'start',
      },
    },
    {
      element: '#menu-chat',
      popover: {
        description:
          'Je kunt kletsen met onze Bloom-assistent. Die is er voor je als je met iets zit of gewoon iets wilt delen.',
        side: 'right',
        align: 'start',
      },
    },
    {
      element: '#menu-statistieken',
      popover: {
        description:
          'In deze grafiek zie je je dagelijkse check-in over de maand heen. Het stelt je in staat om patronen te herkennen en inzichten te verkrijgen. Door bewust deze patronen te observeren, creëer je ruimte voor de eerste stappen naar verandering en groei. Misschien merk je emoties op die je gedrag beïnvloeden, ontdek je terugkerende gedachten die je belemmeren, word je je bewust van bepaalde fysieke klachten of je omgang met andere mensen.',
        side: 'right',
        align: 'start',
      },
    },
    {
      popover: {
        description:
          'Dit is het einde van de tour. Je kunt rechtsboven op elk moment opnieuw beginnen. Voor meer informatie over specifieke functies, klik op het vraagteken (?) symbool.',
        side: 'bottom',
        align: 'start',
      },
    },
  ],
  nextBtnText: 'Volgende',
  prevBtnText: 'Vorige',
  doneBtnText: 'Klaar',
  progressText: '{{current}} van de {{total}}',
})
