import { ComponentType, ReactNode, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { RouteObject } from 'react-router'

import SidebarLayout from '../pages/admins/layouts/SidebarLayout'
import BaseLayout from '../pages/admins/layouts/BaseLayout'

import SuspenseLoader from '../components/SuspenseLoader'

const Loader =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P): ReactNode =>
    (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    )

// Pages

const Overview = Loader(lazy(() => import('../pages/admins/content/overview')))

// Dashboards

// const Dashboard = Loader(
//   lazy(() => import('../pages/admins/content/dashboards/Crypto'))
// )

// Applications

const Messenger = Loader(
  lazy(() => import('../pages/admins/content/applications/Messenger'))
)
const ChatAI = Loader(
  lazy(() => import('../pages/admins/content/applications/ChatAi'))
)

const PeriodHistory = Loader(
  lazy(() => import('../pages/admins/content/applications/Calendar'))
)
const FeelingDiary = Loader(
  lazy(() => import('../pages/admins/content/applications/Psychologie/index'))
)

const UserProfile = Loader(
  lazy(() => import('../pages/admins/content/applications/Users/profile'))
)
const UserSettings = Loader(
  lazy(() => import('../pages/admins/content/applications/Users/settings'))
)

// management
const ManagementUploadData = Loader(
  lazy(() => import('../pages/admins/content/management/UploadData'))
)
const ManagementInstruction = Loader(
  lazy(() => import('../pages/admins/content/management/Instruction'))
)
const ManagementBotTrainer = Loader(
  lazy(() => import('../pages/admins/content/management/BotTrainer'))
)
const ManagementSetting = Loader(
  lazy(() => import('../pages/admins/content/management/Setting'))
)

// Status
// const Status404 = Loader(
//   lazy(() => import('../pages/admins/content/pages/Status/Status404'))
// )

// Auth
const AuthVerifyAccount = Loader(
  lazy(() => import('../pages/auth/verifyAccount'))
)
const AuthOtpCode = Loader(lazy(() => import('../pages/auth/otpCode')))
const AuthForgotPassword = Loader(
  lazy(() => import('../pages/auth/forgotPassword'))
)
const ResetPassword = Loader(lazy(() => import('../pages/auth/resetPassword')))
const Invitation = Loader(lazy(() => import('../pages/auth/invitation')))

const routesDev: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/dashboards" replace />,
      },
      {
        path: '/auth',
        children: [
          {
            path: '',
            element: <Navigate to="login" replace />,
          },
          {
            path: 'forgot-password',
            element: <AuthForgotPassword />,
          },
          {
            path: 'verify-email/:token',
            element: <AuthVerifyAccount />,
          },
          {
            path: 'otp-code',
            element: <AuthOtpCode />,
          },
          {
            path: 'reset-password',
            element: <ResetPassword />,
          },
          {
            path: 'invitation/:token',
            element: <Invitation />,
          },
        ],
      },
      {
        path: '/dashboards',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="psychologie" replace />,
          },
          {
            path: 'messenger',
            element: <Messenger />,
          },
          {
            path: 'chat-ai',
            element: <ChatAI />,
          },
          {
            path: 'kalender',
            element: <PeriodHistory />,
          },
          {
            path: 'psychologie',
            element: <FeelingDiary />,
          },
        ],
      },
      {
        path: 'management',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="transactions" replace />,
          },
          {
            path: 'instructions',
            element: <ManagementInstruction />,
          },
          {
            path: 'bot-trainer',
            element: <ManagementBotTrainer />,
          },
          {
            path: 'upload-data',
            element: <ManagementUploadData />,
          },
          {
            path: 'setting',
            element: <ManagementSetting />,
          },
          {
            path: 'profile',
            children: [
              {
                path: '',
                element: <Navigate to="details" replace />,
              },
              {
                path: 'details',
                element: <UserProfile />,
              },
              {
                path: 'settings',
                element: <UserSettings />,
              },
            ],
          },
        ],
      },
      {
        path: 'overview',
        element: <Overview />,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
]

export default routesDev
