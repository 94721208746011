// import ReactDOM from 'react-dom/client'
// import { HelmetProvider } from 'react-helmet-async'
// import { BrowserRouter } from 'react-router-dom'

// import 'nprogress/nprogress.css'
// import App from './App'
// import { SidebarProvider } from './pages/admins/contexts/SidebarContext'
// import { AppStateProvider } from './state/AppProvider'

// import {
//   PublicClientApplication,
//   EventType,
//   EventMessage,
//   AuthenticationResult,
// } from '@azure/msal-browser'
// import { msalConfig } from './constants/authConfig'

// export const msalInstance = new PublicClientApplication(msalConfig)

// msalInstance.initialize().then(() => {
//   const accounts = msalInstance.getAllAccounts()
//   console.log(accounts)
//   if (accounts.length > 0) {
//     msalInstance.setActiveAccount(accounts[0])
//   }

//   msalInstance.addEventCallback((event: EventMessage) => {
//     console.log('Event:', event)
//     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
//       const payload = event.payload as AuthenticationResult
//       const account = payload.account
//       msalInstance.setActiveAccount(account)
//     }
//   })

//   ReactDOM.createRoot(document.getElementById('root')!).render(
//     <HelmetProvider>
//       <SidebarProvider>
//         <AppStateProvider>
//           <BrowserRouter>
//             <App />
//           </BrowserRouter>
//         </AppStateProvider>
//       </SidebarProvider>
//     </HelmetProvider>
//   )
// })

import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { AppStateProvider } from './state/AppProvider'

import 'nprogress/nprogress.css'
import './assets/styles/index.css'
import App from './App'
import { SidebarProvider } from './pages/admins/contexts/SidebarContext'
import { UserProvider } from './contexts/UserContext'
import './assets/styles/font.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <HelmetProvider>
    <SidebarProvider>
      <AppStateProvider>
        <BrowserRouter>
          <UserProvider>
            <App />
          </UserProvider>
        </BrowserRouter>
      </AppStateProvider>
    </SidebarProvider>
  </HelmetProvider>
)
