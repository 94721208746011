// axiosConfig.ts

import axios, { AxiosInstance } from 'axios'
import { base_url_be } from '../constants/global'

const axiosInstance: AxiosInstance = axios.create({
  baseURL: base_url_be, // Ganti dengan baseURL yang sesuai
  timeout: 18000, // Sesuaikan dengan timeout yang diinginkan
})

// Interceptor untuk menangani request sebelum dikirim
axiosInstance.interceptors.request.use(
  (config) => {
    // Misalkan token disimpan dalam localStorage dengan key 'authToken'
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Interceptor untuk menangani response
axiosInstance.interceptors.response.use(
  (response) => {
    // Lakukan penanganan jika diperlukan
    return response
  },
  (error) => {
    // Lakukan penanganan error seperti menampilkan pesan kesalahan
    return Promise.reject(error)
  }
)

export default axiosInstance
