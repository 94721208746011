import { FC, ReactNode, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Outlet, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import VerifyPeriod from "../../content/verifyPeriod";
import { useUser } from "../../../../contexts/UserContext";
import { dataPeriodGet, phasePeriod } from "../../../../api";

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const navigete = useNavigate();
  const { userData } = useUser();
  const [isPeriodDays, setIsPeriodDays] = useState(false);
  const [isStartDay, setIsStartDay] = useState(false);
  const [dayDiff, setDayDiff] = useState<number>();

  // Check menstruati day to give verification
  const isMenstruatieDays = (
    userId: number | null,
    today: Date,
    menstruationPeriod: { data: any; start_date: string; end_date: string }
  ) => {
    const todayDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    // console.log("aw,dj ", menstruationPeriod);

    let isNotMens = false;

    // Check if it not menst day
    menstruationPeriod.data.forEach((entry: any) => {
      if (
        entry.day === today.getDate() &&
        entry.month === today.getMonth() + 1 &&
        entry.year === today.getFullYear()
      ) {
        isNotMens = true;
      }
    });

    // if not reset the storage
    // Soo it not give verification
    if (!isNotMens) {
      // console.log("tidak menstruatie");
      localStorage.setItem("isVerify", `false${userData?.id}`);
      localStorage.setItem("confirm_period", `false${userData?.id}`);
      localStorage.removeItem("lastShownDate");
    }

    // get the data from storage
    const isVerify = localStorage.getItem("isVerify");
    const lastShownDate = localStorage.getItem("lastShownDate");
    const isVerifyPeriodShownToday =
      lastShownDate === `${todayDate.toDateString()}${userId}`;

    // If it has been verified
    if (isVerify === `true${userId}`) {
      setIsPeriodDays(false);
      return false;
    }

    // If verified has been shown today, will not shown again
    if (isVerifyPeriodShownToday) {
      console.log(
        "sudah berbeda hari ya hari terakhir ",
        lastShownDate,
        " Hari ini : ",
        todayDate
      );

      setIsPeriodDays(false);
      return false;
    }

    // Convert date to js date format
    const startDate = new Date(menstruationPeriod.start_date);
    const endDate = new Date(menstruationPeriod.end_date);
    const nextDayStartDate = new Date(startDate);
    nextDayStartDate.setDate(startDate.getDate() + 1);

    // set time to 0
    todayDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    nextDayStartDate.setHours(0, 0, 0, 0);

    // console.log("todayDate ", todayDate.getTime());
    // console.log("startDate ", startDate.getTime());
    // console.log("nextstartday ", nextDayStartDate);

    // check if it start date or not
    if (todayDate.getTime() === startDate.getTime()) {
      setIsStartDay(true);
    } else if (todayDate.getTime() === nextDayStartDate.getTime()) {
      setIsStartDay(false);
    }

    // Check day after first day
    const dayDifferent = Math.ceil(
      (todayDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
    );

    // Check if today is between start and end date
    if (
      todayDate.getTime() >= startDate.getTime() &&
      todayDate.getTime() <= endDate.getTime()
    ) {
      setDayDiff(dayDifferent);
      // console.log("Hari ke:", dayDiff);

      setIsPeriodDays(true);
      localStorage.setItem(
        "lastShownDate",
        `${todayDate.toDateString()}${userId}`
      );
      localStorage.setItem("isVerify", `false${userId}`);
      return true;
    } else {
      setIsPeriodDays(false);
      return false;
    }
  };

  const fetchData = async () => {
    try {
      const phase = await phasePeriod(userData!.uuid);
      console.log("wadad ", phase);

      const user = userData!.id ? userData!.id : null;

      const today = new Date();

      const year = today.getFullYear();

      // Get data from phase and forcase
      const res = await dataPeriodGet(userData!.uuid, year);

      // Filter to only get the Menstruatie data
      const getPeriodForcase = res.data.filter((entry: { fase: string }) => {
        return entry.fase === "Menstruatie";
      });

      // Filter from day, month and year
      getPeriodForcase.sort(
        (
          a: { day: number; month: number; year: number },
          b: { day: number; month: number; year: number }
        ) => {
          if (a.year !== b.year) return a.year - b.year;
          if (a.month !== b.month) return a.month - b.month;
          return a.day - b.day;
        }
      );

      // create a group array to
      const groupedData = [];
      let currentGroup = [];

      // function get date in month
      const daysInMonth = (month: number, year: number) => {
        return new Date(year, month, 0).getDate();
      };

      const currentDate = new Date();

      // Function to seperate menstruatie
      // if not accordion
      for (let i = 0; i < getPeriodForcase.length; i++) {
        if (currentGroup.length === 0) {
          currentGroup.push(getPeriodForcase[i]);
        } else {
          const lastEntry = currentGroup[currentGroup.length - 1];
          const currentEntry = getPeriodForcase[i];
          const lastDay = lastEntry.day;
          const lastMonth = lastEntry.month;
          const lastYear = lastEntry.year;
          const currentDay = currentEntry.day;
          const currentMonth = currentEntry.month;
          const currentYear = currentEntry.year;

          // Check the next day if it accordion with the previous
          const isNextDay =
            (currentDay === lastDay + 1 &&
              currentMonth === lastMonth &&
              currentYear === lastYear) ||
            (currentDay === 1 &&
              lastDay === daysInMonth(lastMonth, lastYear) &&
              currentMonth === lastMonth + 1 &&
              currentYear === lastYear) ||
            (currentDay === 1 &&
              lastDay === 31 &&
              lastMonth === 12 &&
              currentMonth === 1 &&
              currentYear === lastYear + 1);

          if (isNextDay && currentEntry.id === lastEntry.id) {
            currentGroup.push(currentEntry);
          } else {
            groupedData.push(currentGroup);
            currentGroup = [currentEntry];
          }
        }
      }

      if (currentGroup.length > 0) {
        groupedData.push(currentGroup);
      }

      // variable to push to verify check
      let nearestCheck: any = null;
      let nearestGroup: any = null;
      let nearestDistance = Infinity;

      // Get the nearest array groub from today
      groupedData.forEach((group) => {
        group.forEach((entry) => {
          const entryDate = new Date(entry.year, entry.month - 1, entry.day);
          const distance = Math.abs(
            currentDate.getTime() - entryDate.getTime()
          );
          if (distance < nearestDistance) {
            nearestGroup = group;
            nearestDistance = distance;
          }
        });
      });

      // Format the nearest data
      nearestCheck = {
        data: nearestGroup,
        start_date: `${nearestGroup[0].year}-${nearestGroup[0].month}-${nearestGroup[0].day}`,
        end_date: `${nearestGroup[nearestGroup.length - 1].year}-${
          nearestGroup[nearestGroup.length - 1].month
        }-${nearestGroup[nearestGroup.length - 1].day}`,
      };

      // console.log("Data yang dikelompokkan: ", groupedData);

      // console.log("kjwfaw ", res);
      // console.log("fwku ", getPeriodForcase);
      // console.log("grub data ", groupedData);
      // console.log("grub terdekat ", nearestGroup);
      // console.log("grub check ", nearestCheck);
      // console.log("current grub data ", currentGroup);

      // Send data to check days
      isMenstruatieDays(
        user,
        today,
        nearestCheck
        // phase.data.formatted_phases["menstruatie fase"]
      );
    } catch (error) {
      console.log("errornya : ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [navigete]);

  useEffect(() => {
    const handleStorageChange = () => {
      console.log("awd");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <Box
      sx={{
        flex: 1,
        height: "100%",
      }}
    >
      {isPeriodDays ? (
        <VerifyPeriod isPeriod={isStartDay} late={dayDiff} />
      ) : null}
      {children || <Outlet />}
    </Box>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
};

export default BaseLayout;
