import {
  // Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // Card,
  // CardContent,
  // Container,
  // Grid,
  // Snackbar,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
// import Face1 from '../../../../assets/images/face1.png'
// import Face2 from '../../../../assets/images/face2.png'
// import { useTheme } from "@mui/material/styles";
import { phasePeriod } from '../../../../api'
import { useUser } from '../../../../contexts/UserContext'
import { useNavigate } from 'react-router'
import { IoClose } from 'react-icons/io5'
import assets from '../../../../assets'
// import { AiOutlineQuestionCircle } from "react-icons/ai";
// import useMediaQuery from "@mui/material/useMediaQuery";

interface isPeriod {
  isPeriod: boolean
  late?: number
}

const VerifyPeriod: React.FC<isPeriod> = ({ isPeriod, late }) => {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate()
  const { userData } = useUser()
  const [openModal, setOpenModal] = useState(true)

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleConfirm = () => {
    setOpenModal(false)
    localStorage.setItem("isVerify", `true${userData?.id}`);
    localStorage.setItem("confirm_period", `false${userData?.id}`);
    // localStorage.setItem("isAskToday", "true");
  }

  const handleToEdit = () => {
    setOpenModal(false)
    localStorage.setItem("isVerify", `true${userData?.id}`);
    navigate('/dashboards/kalender')
    localStorage.setItem("confirm_period", `true${userData?.id}`);
    // localStorage.setItem("isAskToday", "true");
  }

  const fetchData = async () => {
    try {
      const phase = await phasePeriod(userData!.uuid)
      console.log('wadad ', phase)
    } catch (error) {
      console.log('errornya : ', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    console.log('sanda ', isPeriod)
    console.log('sanda late ', late)
  }, [isPeriod, late])

  return (
    <>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle id="header-verify">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              // overflowY: "auto",
            }}
          >
            <IoClose
              id="close-modal-alert"
              onClick={handleCloseModal}
              fontSize={"30px"}
              color="#AB955F"
              cursor={"pointer"}
            />
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            // height: "80vh",
            overflowX: "hidden",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "32px" },
              fontWeight: "700",
              color: "#383838",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            {isPeriod
              ? `Ziet ernaar uit dat het je eerste dag van je menstruatie is, ${userData?.first_name} !`
              : `${userData?.first_name}, het lijkt erop dat je menstruatie ${late} ${late === 1 ? 'dag' : 'dagen'} geleden is begonnen. Klopt dit?`}
          </Typography>
          <img
            src={isPeriod ? assets.images.notif1 : assets.images.notif2}
            alt=""
            style={{ height: "155px" }}
          />
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "24px" },
              fontWeight: "500",
              color: "#383838",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            {isPeriod
              ? "Is het waar dat vandaag het begin van je menstruatie is?"
              : "Klopt het dat je weer bent begonnen met menstrueren?"}
          </Typography>
          <Stack
            sx={{
              width: "100%",
              marginTop: "10px",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",

              justifyContent: "center",
            }}
          >
            {isPeriod ? (
              <>
                <Button
                  onClick={handleConfirm}
                  variant="contained"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    fontSize: { sm: "18px", md: "20px" },
                    fontWeight: "600",
                    marginRight: { xs: "0", sm: "40px" },
                    marginBottom: { xs: "13px", sm: "0" },
                    borderRadius: '40px'
                  }}
                >
                  Bevestigen
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    fontSize: { sm: "18px", md: "20px" },
                    fontWeight: "600",
                    borderRadius: '40px'
                  }}
                  onClick={handleToEdit}
                >
                  Nee, ik pas het aan
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleConfirm}
                  variant="contained"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    fontSize: { sm: "18px", md: "20px" },
                    fontWeight: "600",
                    marginRight: { xs: "0", sm: "40px" },
                    marginBottom: { xs: "13px", sm: "0" },
                    borderRadius: '40px'
                  }}
                >
                  Bevestigen
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleToEdit}
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    fontSize: { sm: "18px", md: "20px" },
                    fontWeight: "600",
                    borderRadius: '40px'
                  }}
                >
                  Nee, ik pas het aan
                </Button>
              </>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default VerifyPeriod
